/**
 * TODO: This may also handle gallery (or be used in)
 * and allow for image zooming
 */
import PropTypes from "prop-types";
import { ImgixNextImage } from "@components/imgixNextImage";

export function Image({ src, width, height, alt = null, caption = null }) {
  return (
    <figure className="my-24 lg:w-[calc(100%+5rem)] lg:-ml-10">
      <ImgixNextImage
        src={src}
        alt={alt}
        width={Number(width)}
        height={Number(height)}
        sizes="(min-width: 1024px) 1200px, (min-width: 448px) 95vw, 100vw"
        className="object-contain w-full bg-black-100"
        imgixParams={{
          fit: "crop",
        }}
      />

      {caption && (
        <figcaption className="text-right mt-2 text-xs">{caption}</figcaption>
      )}
    </figure>
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  caption: PropTypes.string,
};
