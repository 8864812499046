/* eslint-disable jsx-a11y/heading-has-content */
import PropTypes from "prop-types";
import { dangerHTML } from "@utils/danger";

export function Heading({ level, text }) {
  switch (level) {
    case 1:
      return (
        <h1
          className="text-black mt-16 mb-6 article-h1"
          {...dangerHTML(text)}
        />
      );
    case 2:
      return (
        <h2
          className="text-black mt-16 mb-6 article-h2"
          {...dangerHTML(text)}
        />
      );
    case 3:
      return (
        <h3
          className="text-black mt-16 mb-6 article-h3"
          {...dangerHTML(text)}
        />
      );
    case 4:
      return (
        <h4 className="text-black mt-16 mb-6 text-xl" {...dangerHTML(text)} />
      );
    case 5:
      return (
        <h5 className="text-black mt-16 mb-6 text-lg" {...dangerHTML(text)} />
      );
    case 6:
      return (
        <h6 className="text-black mt-16 mb-6 text-md" {...dangerHTML(text)} />
      );
    default:
      return null;
  }
}

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  text: PropTypes.string.isRequired,
};
