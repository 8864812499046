/* eslint-disable react/no-danger */
import PropTypes from "prop-types";
import uniqueId from "lodash/uniqueId";

export function ListItem({ children }) {
  return <li className="mt-3">{children}</li>;
}
ListItem.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export function List({ items, style }) {
  switch (style) {
    case "unordered":
      return (
        <ul className="my-8 pl-10 list-circle list-outside text-md">
          {items.map(item => (
            <ListItem key={uniqueId()}>
              <p
                className="text-md text-black-400"
                dangerouslySetInnerHTML={{ __html: item }}
              />
            </ListItem>
          ))}
        </ul>
      );
    case "ordered":
      return (
        <ol className="my-8 pl-10 list-decimal list-outside text-md">
          {items.map(item => (
            <ListItem key={uniqueId()}>
              <p
                className="text-md text-black-400"
                dangerouslySetInnerHTML={{ __html: item }}
              />
            </ListItem>
          ))}
        </ol>
      );
    default:
      return null;
  }
}
List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  style: PropTypes.oneOf(["ordered", "unordered"]).isRequired,
};
