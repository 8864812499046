import PropTypes from "prop-types";
import { ImageCarousel as Carousel } from "@components/carousel";
import { ImgixNextImage } from "@components/imgixNextImage";
import cn from "classnames";

export function ImageCarousel({ images }) {
  const itemMeta = images.map(({ caption }) => ({
    figcaption: caption,
  }));
  return (
    <Carousel
      tagularLocation="BlockImageGallery"
      mobileItemSize="full"
      pageSize={1}
      className="px-0 max-w-none md:my-24 w-[100vw] -ml-[calc((100vw-100%)/2)]"
      listClassNames="bg-sand-100 h-full"
      itemMeta={itemMeta}
      images={images}
      expand
    >
      {images.map(({ src, alt, width, height }) => {
        const isPortrait = height > width;
        return (
          <div
            className={cn("2xl:container 2xl:px-0", {
              "relative w-full h-full flex items-center": isPortrait,
            })}
            key={src}
          >
            <ImgixNextImage
              src={src}
              alt={alt}
              className={cn("object-contain aspect-video", {
                "w-full": !isPortrait,
                "h-full mx-auto w-auto": isPortrait,
              })}
              width={isPortrait ? Number(width) : 1400}
              height={isPortrait ? Number(height) : 650}
              imgixParams={{
                ...(isPortrait
                  ? {
                      w: "auto",
                      h: 1600,
                    }
                  : {}),
                fit: "crop",
              }}
              {...(!isPortrait
                ? {
                    sizes: "(max-width: 1920px) 100vw, 90vw",
                  }
                : {})}
            />
          </div>
        );
      })}
    </Carousel>
  );
}

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      credit: PropTypes.string,
      caption: PropTypes.string,
    })
  ).isRequired,
};
