import PropTypes from "prop-types";
import { ImgixNextImage } from "@components/imgixNextImage";

const { className, sizes, imgixParams } = {
  sizes: {
    landscape: "(min-width: 1024px) 700px, 70vw",
    portrait: "(min-width: 1024px) 1000px, 85vw",
  },
  className: {
    landscape: "aspect-video flex-1",
    portrait:
      "aspect-[9/16] h-auto max-h-full w-[calc(35%-1.5rem)] lg:w-[calc(25%-1.5rem)]",
  },
  imgixParams: {
    landscape: {
      ar: "16:9",
    },
    portrait: {
      ar: "9:16",
    },
  },
};

export function ImageCollage({ images, caption = null, flip = false }) {
  // If collage caption and credit are not provided, use image data to generate fallback.
  // Combining image captions to display under the featured image.
  // fallback when a collage caption is not provided
  const [one, two, three, four] = images;
  const figcaption = [one, two, four, three]
    .map(image => image.caption)
    .filter(Boolean)
    .join(". ");
  return (
    <figure className="my-24 flex flex-wrap space-y-6 w-full lg:w-[calc(100%+10rem)] lg:-ml-20 xl:w-[calc(100%+20rem)] xl:-ml-40">
      <div className="flex gap-x-6 ml-auto mr-0 w-[95%]">
        {[one, two].map((image, i) => {
          const { src, alt } = image;
          return flip ? (
            <div className={i === 0 ? className.portrait : className.landscape}>
              <ImgixNextImage
                src={src}
                alt={alt}
                width={i === 0 ? 225 : 712}
                height={400}
                sizes={i === 0 ? sizes.portrait : sizes.landscape}
                className="object-cover w-full h-full"
                imgixParams={{
                  fit: "crop",
                  ...(i === 0 ? imgixParams.portrait : imgixParams.landscape),
                }}
              />
            </div>
          ) : (
            <div className={i === 0 ? className.landscape : className.portrait}>
              <ImgixNextImage
                src={src}
                alt={alt}
                width={i === 0 ? 712 : 225}
                height={400}
                sizes={i === 0 ? sizes.landscape : sizes.portrait}
                className="object-cover w-full h-full"
                imgixParams={{
                  fit: "crop",
                  ...(i === 0 ? imgixParams.landscape : imgixParams.portrait),
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="flex gap-x-6 w-[95%] max-w-full max-h-112">
        {[three, four].map((image, i) => {
          const { src, alt } = image;
          return flip ? (
            <div className={i === 0 ? className.landscape : className.portrait}>
              <ImgixNextImage
                src={src}
                alt={alt}
                width={i === 0 ? 712 : 225}
                height={400}
                sizes={i === 0 ? sizes.landscape : sizes.portrait}
                className="object-cover w-full h-full"
                imgixParams={{
                  fit: "crop",
                  ...(i === 0 ? imgixParams.landscape : imgixParams.portrait),
                }}
              />
            </div>
          ) : (
            <div className={i === 0 ? className.portrait : className.landscape}>
              <ImgixNextImage
                src={src}
                alt={alt}
                width={i === 0 ? 225 : 712}
                height={400}
                sizes={i === 0 ? sizes.portrait : sizes.landscape}
                className="object-cover w-full h-full"
                imgixParams={{
                  fit: "crop",
                  ...(i === 0 ? imgixParams.portrait : imgixParams.landscape),
                }}
              />
            </div>
          );
        })}
      </div>
      {(caption || figcaption) && (
        <figcaption
          className="container max-w-4xl text-xs text-right text-balance"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<b>Clockwise from top left:</b> ${caption || figcaption}`,
          }}
        />
      )}
    </figure>
  );
}

ImageCollage.propTypes = {
  caption: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      credit: PropTypes.string,
      caption: PropTypes.string,
    })
  ).isRequired,
  flip: PropTypes.bool,
};
