import PropTypes from "prop-types";
import cn from "classnames";

export function EmbedJw({ caption = null, source, width, height }) {
  if (!source) return null;

  const vertical = height > width;

  return (
    <div className={cn("my-12", { "sm:max-w-[385px] sm:mx-auto": vertical })}>
      <div
        className="relative w-full h-0 rounded overflow-hidden"
        style={{ paddingBottom: vertical ? "156.25%" : "56.25%" }}
      >
        <iframe
          className={cn(
            "absolute left-0 top-0 w-full h-full",
            vertical ? "aspect-[9/16]" : "aspect-[16/9]"
          )}
          title="jwplayer"
          src={source}
          allowFullScreen
        />
      </div>
      {caption && <p className="text-xs text-right mt-3">{caption}</p>}
    </div>
  );
}

EmbedJw.propTypes = {
  caption: PropTypes.string,
  source: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
