import PropTypes from "prop-types";

export function EmbedFacebookReel({ src, vid, aspect }) {
  if (!vid && !src) return null;

  const url = src || `www.facebook.com/lonelyplanet/videos/${vid}`;
  const height = aspect.split(":").reduce((w, h) => `${100 * (h / w)}%`);

  return (
    <div
      className="relative w-full h-0 my-12"
      style={{ paddingBottom: height }}
    >
      <iframe
        className="absolute left-0 top-0 w-full h-full"
        src={url}
        title="facebook"
        allowFullScreen
      />
    </div>
  );
}

EmbedFacebookReel.propTypes = {
  aspect: PropTypes.string,
  src: PropTypes.string,
  vid: PropTypes.string,
};

EmbedFacebookReel.defaultProps = {
  aspect: "9:16",
  src: null,
  vid: null,
};
