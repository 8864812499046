import PropTypes from "prop-types";
import { ImgixNextImage } from "@components/imgixNextImage";
import cn from "classnames";
import uniqueId from "lodash/uniqueId";

export function Diptych({ images, caption = null, flip = false }) {
  // If diptych caption and credit are not provided, use image data to generate fallback.
  // Combining image captions to display under the featured image.
  // fallback when a diptych caption is not provided
  const figcaption = images
    .map(image => image.caption)
    .filter(Boolean)
    .map((text, i) => `${i === 0 ? "<b>Left:</b>" : "<b>Right:</b>"} ${text}`)
    .join(" ");
  return (
    <figure
      className={cn(
        "my-24 flex flex-wrap lg:flex-nowrap lg:items-start gap-y-6 gap-x-20 lg:w-[calc(100%+10rem)] lg:-ml-20 xl:w-[calc(100%+20rem)] xl:-ml-40"
      )}
    >
      {images.map((image, i) => {
        const { src, alt } = image;
        return flip ? (
          <div
            key={uniqueId()}
            className={cn({
              "w-11/12 lg:w-7/12": i === 0,
              "w-2/3 lg:w-1/3 lg:flex-1": i > 0,
            })}
          >
            <ImgixNextImage
              src={src}
              alt={alt || ""}
              width={i === 0 ? 800 : 450}
              height={i === 0 ? 600 : 450}
              sizes={
                i > 0
                  ? "(min-width: 1024px) 1000px, 85vw"
                  : "(min-width: 1024px) 700px, 70vw"
              }
              className={cn("object-cover w-full", {
                "lg:mt-24": i === 0,
                "aspect-[4/3]": i === 0,
                "aspect-square": i > 0,
              })}
              imgixParams={{
                fit: "crop",
                ...(i === 0 ? { ar: "4:3" } : { ar: "1:1" }),
              }}
            />
            {(caption || figcaption) && i === 0 && (
              <figcaption
                className={cn(
                  "mt-2 text-xs text-right px-2 text-balance",
                  i === 0 ? "lg:px-20" : "lg:px-6"
                )}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: caption || figcaption,
                }}
              />
            )}
          </div>
        ) : (
          <div
            key={uniqueId()}
            className={cn({
              "w-11/12 lg:w-7/12": i > 0,
              "w-2/3 lg:w-1/3 lg:flex-1": i === 0,
            })}
          >
            <ImgixNextImage
              src={src}
              alt={alt}
              width={i === 0 ? 450 : 800}
              height={i === 0 ? 450 : 600}
              sizes={
                i > 0
                  ? "(min-width: 1024px) 1000px, 85vw"
                  : "(min-width: 1024px) 700px, 70vw"
              }
              className={cn("object-cover w-full", {
                "lg:mt-24": i > 0,
                "aspect-[4/3]": i > 0,
                "aspect-square": i === 0,
              })}
              imgixParams={{
                fit: "crop",
                ...(i === 0 ? { ar: "1:1" } : { ar: "4:3" }),
              }}
            />
            {(caption || figcaption) && i > 0 && (
              <figcaption
                className={cn(
                  "mt-2 text-xs text-right px-2 text-balance",
                  i === 0 ? "lg:px-6" : "lg:px-20"
                )}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: caption || figcaption,
                }}
              />
            )}
          </div>
        );
      })}
    </figure>
  );
}

Diptych.propTypes = {
  caption: PropTypes.string,
  flip: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
      credit: PropTypes.string,
      caption: PropTypes.string,
    })
  ).isRequired,
};
