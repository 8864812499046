import PropTypes from "prop-types";
import cn from "classnames";
import { Ad } from "@components/ad";

export function AdUnit({ adPath, adPlacement, native }) {
  return (
    <Ad
      className={cn("my-12", { "shadow p-6": native })}
      path={adPath}
      placement={adPlacement}
      native={native}
      white
    />
  );
}

AdUnit.propTypes = {
  adPath: PropTypes.string.isRequired,
  adPlacement: PropTypes.string.isRequired,
  native: PropTypes.bool,
};
