import PropTypes from "prop-types";
import { ImgixNextImage } from "@components/imgixNextImage";
import { PlusFill, MinusFill } from "@icons";

export function ShopCard({
  title,
  image,
  price,
  addToCart,
  removeFromCart,
  inCart,
  href,
}) {
  return (
    <article className="relative p-4 bg-white border border-sand-300 rounded-lg h-full flex flex-col">
      <a href={href} target="_blank" rel="noreferrer noopener">
        <ImgixNextImage
          loader={uri => uri.src}
          className="w-full object-contain h-[120px] mb-6"
          width={200}
          height={240}
          src={image?.src}
          alt={image?.alt}
        />
      </a>
      <p className="text-center text-xl lg:text-lg xl:text-xl mb-2">
        <a
          className="text-current"
          href={href}
          target="_blank"
          rel="noreferrer noopener"
        >
          {title}
        </a>
      </p>
      <div className="text-center lg:text-left flex flex-col justify-center items-center">
        <p className="text-md mb-6 lg:text-black">{price}</p>
        {inCart ? (
          <button
            type="button"
            className="text-blue mt-4 lg:mt-0 font-bold flex items-center lg:border lg:border-black-300 lg:hover:border-blue lg:rounded-lg lg:px-4 lg:py-2"
            onClick={removeFromCart}
          >
            <span className="mr-2 p-0.5 border border-current rounded-full w-4 h-4">
              <MinusFill className="w-full h-full" />
            </span>
            Remove from Cart
          </button>
        ) : (
          <button
            type="button"
            className="text-blue mt-4 lg:mt-0 font-bold flex items-center lg:border lg:border-black-300 lg:hover:border-blue lg:rounded-lg lg:px-4 lg:py-2"
            onClick={addToCart}
          >
            <span className="mr-2 p-0.5 border border-current rounded-full w-4 h-4">
              <PlusFill className="w-full h-full" />
            </span>
            Add to Cart
          </button>
        )}
      </div>
    </article>
  );
}

ShopCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  price: PropTypes.string,
  href: PropTypes.string,
  addToCart: PropTypes.func,
  removeFromCart: PropTypes.func,
  inCart: PropTypes.bool,
};
