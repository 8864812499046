import PropTypes from "prop-types";

export function EmbedTwitter({ children }) {
  return (
    <blockquote className="mx-auto my-12 w-4/5">
      {/**
       * This inner blockquote is required by the Twitter script, and will be replaced with a `div`
       * when that script runs, thus the "redundant" wrapping blockquote.
       */}
      <blockquote className="twitter-tweet">
        {children}
        <script src="https://platform.twitter.com/widgets.js" async />
      </blockquote>
    </blockquote>
  );
}

EmbedTwitter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    /**
     * String interpolation creates an array, such that `See ${location}`
     * arrives as ["See ", "Germany"]
     */
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
