import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import Script from "next/script";
import withRetry from "fetch-retry";
import cn from "classnames";

const TIKTOK_OEMBED_BASE_URL = `https://www.tiktok.com/oembed`;

export function EmbedTiktok({ url }) {
  const [error, setError] = useState(undefined);
  const [scriptSrc, setScriptSrc] = useState(undefined);
  const [html, setHTML] = useState(undefined);

  const ref = useRef(null);

  useEffect(() => {
    const fetchRetry = withRetry(window.fetch);
    fetchRetry(`${TIKTOK_OEMBED_BASE_URL}?url=${url}`, {
      retries: 3,
      retryDelay: attempt => 2 ** attempt * 1000,
    })
      .then(res => res.json())
      .then(res => {
        if (res && res.status_msg) throw new Error(res.status_msg);

        if (!res || !res.html)
          throw new Error("API response doesn't look right");

        const htmlString = res.html;

        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlString;

        const scriptTag = tempElement.getElementsByTagName("script")[0];

        setScriptSrc(scriptTag && scriptTag.src);
        setHTML(htmlString.substr(0, htmlString.indexOf("<script")));
      })
      .catch(err => setError(err));
  }, [url]);

  if (error) return <div>Error: {JSON.stringify(error)}</div>;

  return (
    <>
      <div
        ref={ref}
        className={cn(
          "w-[323px] h-[770px] overflow-hidden z-0",
          html ? "flex" : "hidden"
        )}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: html || "" }}
      />
      {scriptSrc && <Script src={scriptSrc} strategy="afterInteractive" />}
    </>
  );
}

EmbedTiktok.propTypes = {
  url: PropTypes.string.isRequired,
};
