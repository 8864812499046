import PropTypes from "prop-types";
import { dangerHTML } from "@utils/danger";

export function Paragraph({ text }) {
  return <p className="text-md my-6 text-black-400" {...dangerHTML(text)} />;
}

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
};
