import { withIcon } from "@icons/_withIcon";

export const SailyLogo = withIcon(
  ({ primary }) => (
    <>
      <path
        d="M21.4089 466.404C35.7579 466.799 50.0419 470.301 63.2269 476.908L111.03 500.865C146.375 518.578 188.01 518.55 223.332 500.791L270.516 477.067C298.598 462.947 331.682 462.932 359.777 477.025L389.614 491.992C436.036 515.279 490.686 515.385 537.198 492.28L568.576 476.692C596.59 462.776 629.499 462.848 657.451 476.887L687.233 491.845C733.8 515.233 788.65 515.285 835.26 491.984L865.51 476.864C876.97 471.137 889.25 467.753 901.68 466.713L932.27 402.103C899.74 397.674 866.2 402.982 836.1 418.031L805.85 433.151C777.79 447.177 744.78 447.146 716.754 433.068L686.972 418.11C640.535 394.787 585.852 394.667 539.314 417.785L507.936 433.373C479.938 447.281 447.049 447.217 419.105 433.2L389.268 418.233C342.595 394.82 287.621 394.846 240.969 418.302L193.785 442.026C177.026 450.453 157.27 450.466 140.499 442.061L92.6969 418.105C68.2609 405.859 41.55 400.035 14.95 400.634L21.4089 466.404Z"
        fill={primary}
      />
      <path
        d="M942.12 503.33V500.084C955.1 477.832 991.29 397.574 1004.73 364.194L1007.98 356.313L927.78 185.245C910.62 148.157 904.13 134.712 893.47 123.122V120.341H1015.4V123.122C1012.15 124.977 1009.83 131.467 1009.83 137.03C1009.83 147.693 1014.93 158.819 1016.79 164.383L1059.9 270.547H1061.76L1097.45 176.436C1102.55 162.992 1106.73 147.229 1106.73 136.567C1106.73 129.149 1103.02 124.513 1101.16 123.122V120.341H1187.39V123.122C1178.59 136.567 1162.36 174.582 1149.38 205.643L1027.42 503.33H942.12Z"
        fill={primary}
      />
      <path
        d="M774.05 343.455V340.674C782.86 325.375 785.64 315.639 785.64 295.241V86.512C785.64 70.398 781.57 55.176 775.44 47.619V44.788C799.67 43.032 860.77 17.721 878.72 1.142L882.36 2.92999C878.06 16.032 874.65 57.077 874.65 79.703C874.65 148.555 874.65 220.799 874.65 289.678C874.65 307.758 878.36 324.911 886.24 341.137V343.455H774.05Z"
        fill={primary}
      />
      <path
        d="M637.047 343.329V340.547C645.856 325.249 648.637 315.513 648.637 295.115V191.268C648.637 175.506 645.392 157.843 638.438 149.035V146.253C660.963 144.748 720.454 122.485 735.33 108.747L737.65 110.138V289.551C737.65 307.632 741.36 324.785 749.24 341.011V343.329H637.047Z"
        fill={primary}
      />
      <path
        d="M737.63 73.17C723.314 86.051 670.61 108.998 645.864 111.992V39.624C667.999 34.152 721.865 11.403 737.63 0.802002V73.17Z"
        fill={primary}
      />
      <path
        d="M338.191 281.21C338.191 238.559 372.498 226.505 421.639 220.015C458.264 214.915 513.432 209.352 513.432 190.808C513.432 174.582 490.716 167.628 459.655 167.628C420.248 167.628 386.869 178.754 366.471 188.954V127.295C398.923 117.095 432.302 111.996 480.053 111.996C539.394 111.996 602.443 124.977 602.443 201.471V283.992C602.443 303.463 603.834 322.007 613.57 341.015V343.333H513.432V302.999C500.451 324.325 472.635 349.359 425.812 349.359C374.816 349.359 338.191 322.471 338.191 281.21ZM428.13 271.474C428.13 292.336 441.574 299.754 460.582 299.754C480.053 299.754 501.842 292.336 513.432 281.674V228.823C502.306 238.559 476.344 243.658 456.409 246.44C436.938 249.685 428.13 257.566 428.13 271.474Z"
        fill={primary}
      />
      <path
        d="M154.653 348.89C87.895 348.89 46.87 337.277 7.46402 323.833L0.738037 252.461L3.05603 251.534C40.608 273.786 100.876 285.84 146.772 285.84C196.377 285.84 216.312 273.786 216.312 255.706C216.312 239.48 193.596 235.308 175.979 232.526L104.121 220.472C54.516 212.128 0.738037 193.584 0.738037 130.997C0.738037 56.358 78.623 23.442 164.389 23.442C216.776 23.442 269.163 31.323 301.615 47.549L276.117 109.672C240.883 92.055 196.841 83.71 162.071 83.71C118.029 83.71 100.876 95.3 100.876 110.135C100.876 127.289 121.274 130.997 138.427 133.779L203.795 144.905C271.017 155.568 318.768 177.821 318.768 235.308C318.768 316.438 238.102 348.89 154.653 348.89Z"
        fill={primary}
      />
    </>
  ),
  { viewBox: "0 0 1188 515", fill: "none" }
);
