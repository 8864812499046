import PropTypes from "prop-types";
import cn from "classnames";

import {
  AdUnit,
  Diptych,
  EmbedCallout,
  EmbedFacebookReel,
  EmbedJw,
  EmbedTwitter,
  EmbedYouTube,
  EmbedTiktok,
  Heading,
  Image,
  ImageCollage,
  ImageCarousel,
  List,
  ListicleItem,
  Paragraph,
} from "@components/blocks";

import { RelatedBooks } from "@pageComponents/articles/relatedBooks";
import styles from "./styles.module.css";
import { SailyAd } from "./sailyAd";

export function Blocks({ html }) {
  const formatBlocks = (block, index, listicleItemIndex = null) => {
    const key = `${block.type}-${index}`;
    switch (block.type) {
      case "saily_ad":
        return <SailyAd key={key} {...block.data} />;
      case "ad_unit":
        return <AdUnit key={key} {...block.data} />;
      case "header":
        return <Heading key={key} {...block.data} />;
      case "paragraph":
        return <Paragraph key={key} {...block.data} />;
      case "list":
        return <List key={key} {...block.data} />;
      case "listicle_item":
        return (
          <ListicleItem key={key} number={listicleItemIndex} {...block.data} />
        );
      case "callout":
        return <EmbedCallout key={key} {...block.data} />;
      // TODO remove block.data.meta when Editorjs migration is complete.
      // and Spain articles are migrated.
      case "canto_image":
        if (block.data.meta) {
          return <Image key={key} {...block.data.meta} />;
        }
        return <Image key={key} {...block.data} />;
      case "canto_carousel":
        return (
          <ImageCarousel
            key={key}
            images={block.data.images.map(image => image)}
          />
        );
      case "canto_collage":
        return (
          <ImageCollage
            key={key}
            images={block.data.images.map(image => image)}
            flip={block.data.flip}
          />
        );
      case "canto_diptych":
        return <Diptych key={key} {...block.data} />;
      case "embed":
        if (block.data.service === "youtube") {
          return <EmbedYouTube key={key} {...block.data} />;
        }
        if (block.data.service === "jwplayer") {
          return <EmbedJw key={key} {...block.data} />;
        }
        return null;
      case "EmbedFacebook":
        return <EmbedFacebookReel key={key} {...block.data} />;
      case "EmbedTwitter":
        return <EmbedTwitter key={key} {...block.data} />;
      case "embed_tiktok":
        return (
          <div key={key} className="mx-auto w-content">
            <EmbedTiktok url={block.data?.media?.url} />
          </div>
        );
      case "relatedBooks":
        return (
          <RelatedBooks
            key={key}
            context={block.data?.context}
            tagularCtx={{
              location: "ARTICLE MODULE_SHOP",
            }}
            className="lg:px-12 rounded w-screen -ml-4 md:w-[calc(100%+5rem)] md:-ml-10"
          />
        );
      default:
        return null;
    }
  };

  let listicleItemIndex = 1;

  return (
    <div className={cn("lg:w-full", styles.block)}>
      {html.map((block, index) => {
        if (block.type === "listicle_item") {
          // get the index of the listicle item to assign
          // a number to item if isNumbered is true
          // eslint-disable-next-line no-plusplus
          return formatBlocks(block, index, listicleItemIndex++);
        }
        return formatBlocks(block, index);
      })}
    </div>
  );
}

Blocks.propTypes = {
  html: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.shape({}).isRequired,
    })
  ).isRequired,
};
