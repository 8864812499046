import PropTypes from "prop-types";
import { Carousel } from "@components/carousel";
import { PoiCard } from "@components/cards/poi";
import { withSave } from "@components/_hocs/withSave";

const POICardWSave = withSave(PoiCard);

export function MentionedPois({ mentionedPois }) {
  if (!mentionedPois[0]) return null;

  return (
    <Carousel
      pageSize={3}
      contained={false}
      scrollPadding={false}
      tagularPosition="MENTIONEDPOIS"
    >
      {mentionedPois.map(poi => (
        <POICardWSave
          key={poi.esid}
          tagularPosition="MENTIONEDPOIS"
          hocTagularCtx={{ name: "MENTIONEDPOIS" }}
          category="attractions"
          {...poi}
        />
      ))}
    </Carousel>
  );
}

MentionedPois.propTypes = {
  mentionedPois: PropTypes.arrayOf(
    PropTypes.shape({
      topChoice: PropTypes.bool,
      href: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      miles: PropTypes.number,
      cost: PropTypes.string,
      places: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired
      ),
    })
  ).isRequired,
};
