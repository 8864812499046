import PropTypes from "prop-types";

export function EmbedCallout({ children }) {
  return (
    <div className="my-12 border-l-2 border-blue-400 bg-blue-100 p-8">
      {children}
    </div>
  );
}

EmbedCallout.propTypes = {
  children: PropTypes.shape({ type: PropTypes.string }).isRequired,
};
