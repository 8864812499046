import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { useRouter } from "next/router";
import { tagular } from "@cohesion/tagular";

/**
 * @typedef {Object} TagularCtxType
 * @property {string} location
 */

/**
 * @typedef {Object} BookImageType
 * @property {string} src
 */

/**
 * @typedef {Object} PriceType
 * @property {string} amount
 */

/**
 * @typedef {Object} ProductType
 * @property {PriceType} price
 */

/**
 * @typedef {Object} BookType
 * @property {string} title
 * @property {string} url
 * @property {string} id
 * @property {string} excerpt
 * @property {ProductType[]} variants
 * @property {BookImageType[]} images
 */

/**
 * @typedef {Object} ShopifyBooksHook
 * @property {Function} handleCreateCart
 * @property {Function} handleUpdateCart
 * @property {Object} error
 * @property {BookType[]} books
 * @property {Object} hasQueried
 * @property {Object} fauxCart
 */

/**
 * Custom hook to manage Shopify books.
 *
 * @param {Object} params
 * @param {TagularCtxType} params.tagularCtx
 * @param {string} params.queryURL
 * @param {Object[]} params.body
 * @returns {ShopifyBooksHook}
 *
 * @example
 * const { handleCreateCart, handleUpdateCart, error, books, hasQueried, fauxCart } = useShopifyBooks({
 *  queryURL: "/api/shopify/books/dest",
 *  body: [{ title: "tag", type: "place" }],
 *  tagularCtx: {
 *   location: "location",
 *  },
 * });
 */
export function useShopifyBooks({ tagularCtx, queryURL, body }) {
  if (!queryURL) {
    throw new Error(
      /* eslint-disable-next-line no-template-curly-in-string */
      "`useShopifyBooks` requires `queryURL` (ie: `/api/shopify/books/dest/${destinationPaths}`)"
    );
  }
  const hasQueried = useRef(false);
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);

  const [fauxCart, setFauxCart] = useState([]);
  const router = useRouter();

  useEffect(() => {
    if (hasQueried.current) return;

    const get = async () => {
      const bookData = await fetch(queryURL, {
        method: "POST",
        body,
      })
        .then(r => r.json() || [])
        .then(json => json || [])
        .catch(e => setError(e));

      if (bookData[0]) {
        setBooks([...bookData]);
      }

      hasQueried.current = true;
    };

    get();
  }, [queryURL, body]);

  const handleUpdateCart = (method, product) => {
    if (method === "add") {
      setFauxCart(prev => [...prev, product]);
    }
    if (method === "remove") {
      const next = fauxCart.filter(item => item.id !== product.id);
      setFauxCart(next);
    }

    if (!["add", "remove"].includes(method)) {
      throw new Error("`handleUpdateCart` received an unsupported method");
    }

    const parent = books.find(book =>
      book.variants.some(variant => variant.id === product.id)
    );

    tagular("product_click", {
      actionOutcome: method === "add" ? "ADDTOCART" : "REMOVEFROMCART",
      correlationId: "",
      product: {
        location: tagularCtx.location || "HUBS MODULE_SHOP",
        name: parent.title,
        variant: product.title,
        sku: product.sku,
        price: Number(product.price.amount),
        quantity: 1,
        text: method === "add" ? "ADD TO CART" : "REMOVE FROM CART",
        brand: "shop",
        position: fauxCart.length + 1,
      },
      outboundUrl: "NULL",
    });
  };
  const handleCreateCart = async () => {
    const ids = fauxCart.map(item => item.id);

    await fetch("/api/shopify/cart/create", {
      method: "POST",
      body: JSON.stringify(ids),
    })
      .then(r => r.json())
      .then(json => {
        const cid = json.id.split("/").pop();

        tagular("cart_started", {
          cartContext: {
            cartInstanceId: cid,
            pageReferrer: router.asPath,
          },
          referrer: router.asPath,
        });

        tagular("product_click", {
          product: {
            location: tagularCtx.location || "HUBS MODULE_SHOP",
            text: "GO TO CHECKOUT",
            position: 0,
            brand: "shop",
          },
        });

        router.push(json.checkoutUrl);
      })
      .catch(e => setError(e));
  };

  return {
    handleCreateCart,
    handleUpdateCart,
    error,
    books,
    hasQueried,
    fauxCart,
  };
}

useShopifyBooks.propTypes = {
  tagularCtx: PropTypes.shape({
    location: PropTypes.string,
  }),
  queryURL: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
